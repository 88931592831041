import {Chip, FormControl} from 'platform/components';
import {Box, Grid, Hide, Show, Stack} from 'platform/foundation';

import {useController} from 'react-hook-form';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {VehicleFormType} from '../types/VehicleFormType';

interface FeaturesFieldProps extends TestIdProps {
  control: FormControl<VehicleFormType>;
  features: string[] | Nullish;
}

export function FeaturesField(props: FeaturesFieldProps) {
  const {field} = useController({
    name: 'features',
    control: props.control,
  });
  const {getFeatureLabel} = useCatalogue();

  const handleChange = (feature: string) => (isSelected: boolean) => {
    if (isSelected) {
      field.onChange(field.value.filter((value) => value !== feature));
    } else {
      field.onChange([...field.value, feature]);
    }
    props.control.onChange();
  };

  return (
    <Stack
      direction={['row', 'row', 'column', 'column']}
      spacing={2}
      wrap
      data-testid={suffixTestId('featuresChips', props)}
    >
      <Hide onDesktop onNotebook>
        {props.features?.map((feature) => (
          <Chip
            key={feature}
            label={getFeatureLabel(feature)}
            value={field.value?.includes(feature)}
            onChange={handleChange(feature)}
            data-testid={suffixTestId(feature, props)}
          />
        ))}
      </Hide>
      <Show onDesktop onNotebook>
        <Grid columns={2} spacing={2}>
          {props.features?.map((feature) => (
            <Box flex={1} key={feature}>
              <Chip
                label={getFeatureLabel(feature)}
                value={field.value?.includes(feature)}
                onChange={handleChange(feature)}
                data-testid={suffixTestId(feature, props)}
              />
            </Box>
          ))}
        </Grid>
      </Show>
    </Stack>
  );
}
