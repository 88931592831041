import {Alert, DataStatus} from 'platform/components';
import {Heading, Show, Space, Text} from 'platform/foundation';

import {Trans} from 'react-i18next';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useGetRequestCountsQuery} from '../../../store/priceReportApi';

interface WelcomeSidebarProps extends TestIdProps {
  requestId?: string;
  isUpdateRequestLoading: boolean;
  isChanged: boolean;
}

export function WelcomeSidebar(props: WelcomeSidebarProps) {
  const {data: requestCounts, isFetching: isRequestCountsFetching} = useGetRequestCountsQuery({
    id: props.requestId ?? '',
  });

  const count = (requestCounts?.stock ?? 0) + (requestCounts?.sold ?? 0);

  return (
    <>
      <Heading size={3}>{i18n.t('page.create.labels.welcomeToPriceReport')}</Heading>
      <Space vertical={6} />
      <Text size="small">
        <Trans
          i18nKey="page.create.labels.about1Paragraph"
          components={{
            bold: <Text size="small" alternative inline />,
          }}
        />
      </Text>
      <Space vertical={4} />
      <Text size="small">{i18n.t('page.create.labels.about2Paragraph')}</Text>
      <Space vertical={6} />
      <DataStatus
        isLoading={props.isUpdateRequestLoading || isRequestCountsFetching || props.isChanged}
        minHeight={15}
      >
        <Show when={count > 0}>
          <Alert
            type="inline"
            variant="info"
            title={i18n.t('page.create.labels.similarVehicles', {
              soldCount: String(count),
            })}
            data-testid={suffixTestId('similarVehicles', props)}
          />
        </Show>
        <Show when={count === 0}>
          <Alert
            type="inline"
            variant="warning"
            title={i18n.t('page.create.labels.vehicleNotFound')}
            data-testid={suffixTestId('noVehicleFound', props)}
          />
        </Show>
      </DataStatus>
    </>
  );
}
