import {Align, Box, Heading, Hide, HStack, Space, Text, useDevice} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber} from 'platform/locale';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Customized,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import {useTheme} from 'styled-components';

import {useRef} from 'react';

import {head, last} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {ThisVehicleTooltip} from '../../../components/ThisVehicleTooltip';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';
import {getValueOrEmptyString} from '../../../utils/getValueOrEmptyString';

interface ActualPriceChartProps {
  chartData?: number[];
  averagePrice?: {value: number; unit: string};
  vehiclePrice?: number;
}

const CHART_HEIGHT = 250;
const AXIS_OFFSET = 100;
const ROUND_TO_VALUE = 100;
const axisDomain: YAxisProps['domain'] = [
  (minValue: number) =>
    Math.max(0, Math.floor((minValue - AXIS_OFFSET) / ROUND_TO_VALUE) * ROUND_TO_VALUE),
  'auto',
];

export function ActualPriceChart(props: ActualPriceChartProps) {
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();
  const theme = useTheme();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const chartData =
    props.chartData?.map((value) => ({
      value,
    })) ?? [];

  const minimalValue = head(props.chartData ?? []);
  const maximalValue = last(props.chartData ?? []);

  const xPositionOfThisVehicle = chartData.findIndex((item) => item.value === props.vehiclePrice);

  const isSmallDevice = device === 'mobile' || device === 'tablet';
  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false);

  return (
    <Hide when={isNilOrEmpty(chartData)}>
      <Box>
        <Align center>
          <Heading size={4}>{i18n.t('entity.overviewChart.labels.actualPriceChart')}</Heading>
        </Align>
        <Space vertical={6} />
        <Box ref={wrapperRef} flex={1}>
          <ResponsiveContainer width={chartWidth} height={CHART_HEIGHT}>
            <ComposedChart
              data={chartData}
              margin={isSmallDevice ? {right: 20} : undefined}
              /* CSS is needed to allow ThisVehicleTooltip overflow from the chart, because it can be higher than the chart area */
              /* eslint-disable-next-line react/forbid-component-props */
              css={`
                .recharts-surface {
                  overflow: visible;
                }
              `}
            >
              <CartesianGrid stroke={theme.colors.palettes.neutral[30][100]} vertical={false} />
              <Area
                dataKey="value"
                fill={theme.colors.palettes.orange[10][100]}
                stroke={theme.colors.palettes.orange[60][100]}
                strokeWidth={3}
                isAnimationActive={isAnimationActive}
              />
              <YAxis
                dataKey="value"
                type="number"
                tickFormatter={(value) => `${formatNumber(Math.round(value / 1000))}k`}
                tickLine={false}
                fontSize={12}
                axisLine={false}
                tick={{fill: theme.colors.text.primary}}
                domain={axisDomain}
              >
                <Label
                  value={i18n.t('entity.overviewChart.labels.priceInThousands')}
                  angle={-90}
                  position="insideLeft"
                  fontSize={12}
                  fill={theme.colors.text.primary}
                />
              </YAxis>
              <XAxis visibility="hidden">
                <Label
                  value={i18n.t('entity.overviewChart.labels.advertisedCars')}
                  position="centerBottom"
                  fontSize={12}
                  fill={theme.colors.text.primary}
                />
              </XAxis>
              {props.averagePrice && (
                <ReferenceLine
                  y={props.averagePrice.value}
                  stroke={theme.colors.palettes.neutral[60][100]}
                  pointerEvents="none"
                  strokeDasharray={3}
                >
                  <Label
                    pointerEvents="none"
                    content={(p) => {
                      const cartesianViewBox = p.viewBox && 'x' in p.viewBox ? p.viewBox : null;

                      const valueRange = (maximalValue ?? 0) - (minimalValue ?? 0);
                      const rangePerPixel = valueRange / CHART_HEIGHT;
                      const labelHeight = 30;
                      const offset = 2;
                      const areLabelsColliding =
                        ((maximalValue ?? 0) - (props.averagePrice?.value ?? 0)) / rangePerPixel <
                        labelHeight + offset;

                      return (
                        <foreignObject
                          x={(cartesianViewBox?.x ?? 0) - 3}
                          y={
                            (cartesianViewBox?.y ?? 0) -
                            (areLabelsColliding ? -offset : labelHeight)
                          }
                          height={labelHeight}
                          width={cartesianViewBox?.width}
                          pointerEvents="none"
                        >
                          <HStack justify="flex-end">
                            <Box
                              borderRadius="xSmall"
                              backgroundColor="palettes.neutral.40.100"
                              paddingHorizontal={1}
                            >
                              <Text size="xxSmall" align="right" color="secondary">
                                Ø {i18n.t('entity.overviewChart.labels.averageSold')}
                                <br />
                                {getValueOrEmptyString(props.averagePrice, (averagePrice) =>
                                  formatCurrency(averagePrice.value, averagePrice.unit)
                                )}
                              </Text>
                            </Box>
                          </HStack>
                        </foreignObject>
                      );
                    }}
                  />
                </ReferenceLine>
              )}

              <ReferenceLine y={minimalValue} visibility="hidden" pointerEvents="none">
                <Label
                  pointerEvents="none"
                  content={(p) => {
                    const cartesianViewBox = p.viewBox && 'x' in p.viewBox ? p.viewBox : null;
                    return (
                      <foreignObject
                        x={cartesianViewBox?.x ?? 0}
                        y={(cartesianViewBox?.y ?? 0) + 2}
                        height={cartesianViewBox?.width}
                        width={cartesianViewBox?.width}
                        pointerEvents="none"
                      >
                        <HStack justify="flex-start">
                          <Text size="xxSmall">
                            {/* Required color is not possible to set via <Text color="..."> */}
                            {/* eslint-disable-next-line react/forbid-dom-props */}
                            <span style={{color: theme.colors.palettes.orange[80][100]}}>
                              {formatNumber(minimalValue)}
                            </span>
                          </Text>
                        </HStack>
                      </foreignObject>
                    );
                  }}
                />
              </ReferenceLine>
              <ReferenceLine y={maximalValue} visibility="hidden" pointerEvents="none">
                <Label
                  pointerEvents="none"
                  content={(p) => {
                    const cartesianViewBox = p.viewBox && 'x' in p.viewBox ? p.viewBox : null;
                    return (
                      <foreignObject
                        x={cartesianViewBox?.x ?? 0}
                        y={(cartesianViewBox?.y ?? 0) - 16}
                        height={cartesianViewBox?.width}
                        width={cartesianViewBox?.width}
                        pointerEvents="none"
                      >
                        <HStack justify="flex-end">
                          <Text size="xxSmall">
                            {/* Required color is not possible to set via <Text color="..."> */}
                            {/* eslint-disable-next-line react/forbid-dom-props */}
                            <span style={{color: theme.colors.palettes.orange[80][100]}}>
                              {formatNumber(maximalValue)}
                            </span>
                          </Text>
                        </HStack>
                      </foreignObject>
                    );
                  }}
                />
              </ReferenceLine>
              {xPositionOfThisVehicle >= 0 && (
                <Customized
                  component={
                    <ThisVehicleTooltip
                      xPositionOfThisVehicle={xPositionOfThisVehicle}
                      shouldShowCircle
                    />
                  }
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Hide>
  );
}
