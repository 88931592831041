export function getStatisticVariant(
  myCarPrice: number,
  soldCarsPrice: number
): 'success' | 'warning' | 'neutral' {
  // 3% better
  if (myCarPrice > soldCarsPrice * 1.03) {
    return 'success';
  }
  // 3% worse
  if (myCarPrice < soldCarsPrice * 0.97) {
    return 'warning';
  }
  return 'neutral';
}
