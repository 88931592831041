import {isFeatureEnabled} from 'feature-flags';
import {Card} from 'platform/components';
import {Box, Space, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {featureFlags, i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {VehicleInfo} from '../../components/VehicleInfo/VehicleInfo';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {convertMileageToAlphaMileageRange} from '../../utils/convertMileageToAlphaMileageRange';
import {DrillDown, DrillDownProps} from './components/DrillDown/DrillDown';

const HIDDEN_LEVELS: DrillDownProps['hiddenLevels'] = ['make', 'model'];

export function DrillDownPage(props: TestIdProps) {
  const {data: priceReport} = usePriceReport();
  const catalogue = useCatalogue();

  if (!isFeatureEnabled(featureFlags.PRICE_REPORT_DRILL_DOWN)) {
    return null;
  }

  if (!priceReport) {
    return null;
  }

  return (
    <>
      <Helmet title={i18n.t('page.drillDown.title')} />
      <VStack align="center">
        <Box width="100%" maxWidth={300} padding={2}>
          <Card>
            <VehicleInfo catalogue={catalogue} vehicle={priceReport.vehicle} />
            <Space vertical={6} />
            <DrillDown
              regions={[priceReport.market]}
              currency={priceReport.purchasePrice.currency}
              hiddenLevels={HIDDEN_LEVELS}
              permanentFilters={{
                make: priceReport.vehicle.make,
                model: priceReport.vehicle.modelFamily,
              }}
              selectedRow={{
                make: priceReport.vehicle.make,
                model: priceReport.vehicle.modelFamily,
                year: priceReport.vehicle.firstRegistrationOnYear?.toString(),
                fuel_type: priceReport.vehicle.fuelType,
                drive: priceReport.vehicle.drive,
                transmission: priceReport.vehicle.transmission,
                power: priceReport.vehicle.power?.toString(),
                mileage_range: convertMileageToAlphaMileageRange(priceReport.vehicle.mileage),
                car_style: priceReport.vehicle.bodyStyle,
              }}
              data-testid={suffixTestId('drilldown', props)}
            />
          </Card>
        </Box>
      </VStack>
    </>
  );
}
